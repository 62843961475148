
import CubeLoader from '@/components/Loaders/CubeLoader.vue'
import { CsFlex } from '@consteel/csuetify'
import { loginOauth } from '@/modules/auth'
import Vue from 'vue'

export default Vue.extend({
  components: {
    CubeLoader,
    CsFlex,
  },
  async mounted() {
    const authToken = this.$route.query.idToken
    await loginOauth(authToken.toString())
    window.close()
  },
})
